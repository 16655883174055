import Keycloak from 'keycloak-js'
import { environment } from './environment'
import { clientProperties } from '../components/partials/Navigation/LicenseServerAppTop'

//keycloak init options
export const KEYCLOAK_INIT_OPTIONS: Keycloak.KeycloakConfig = {
  url: environment.LOGIN_URL,
  realm: environment.LOGIN_REALM,
  clientId: 'login',
}

export const keycloak = new Keycloak(KEYCLOAK_INIT_OPTIONS)

const updateToken = (): void => {
  keycloak
    .updateToken(300)
    .then((success) => {
      if (!success) {
        console.error('could not update token')
        return
      }
      setTokens(keycloak.token ?? '', keycloak.idToken ?? '', keycloak.refreshToken ?? '')
    })
    .catch((e) => {
      console.error('problem while refreshing token')
      console.error(e)
    })
}

export const keycloakEventHandler = (event: unknown): void => {
  if (event === 'onAuthSuccess') {
    if (keycloak.isTokenExpired()) {
      updateToken()
    }
  }
  if (event === 'onReady') {
    updateToken()
  }
  if (event === 'onTokenExpired') {
    updateToken()
  }
}

export const isLoggedIn = (): boolean => {
  return !!localStorage.getItem('keycloak-token')
}

export const setKeycloakClient = (client: string): void => {
  localStorage.setItem('client', client)
}

export const getKeycloakClient = (): string => {
  console.log('getKeycloakClient', localStorage.getItem('client') || '')
  return localStorage.getItem('client') || ''
}

const prefixKeycloakKeys = (prefill?: string, operationName?: string): string => {
  const operationNames = ['getUserProfiles']
  const client = prefill || getKeycloakClient()
  console.log('prefixKeycloakKeys', { client })
  if (client === 'neutral@cs-instruments.com' && operationNames.includes(operationName || '')) {
    return client
  }
  return ''
}

export const getTokens = (operationName?: string): [string | null, string | null] => {
  const prefix = prefixKeycloakKeys(undefined, operationName)
  return [localStorage.getItem(`${prefix}keycloak-token`), localStorage.getItem(`${prefix}keycloak-refresh-token`)]
}

export const setTokens = (token: string, idToken: string, refreshToken: string, prefix = ''): void => {
  // const prefix = getKeycloakClient()
  // console.log('setTokens', { prefix })
  localStorage.setItem(`${prefix}keycloak-token`, token)
  localStorage.setItem(`${prefix}keycloak-refresh-token`, refreshToken)
  localStorage.setItem(`${prefix}keycloak-id-token`, idToken)
}

export const keycloakLogout = async (): Promise<void> => {
  const prefix = prefixKeycloakKeys()
  localStorage.removeItem(`${prefix}keycloak-token`)
  localStorage.removeItem(`${prefix}keycloak-refresh-token`)
  localStorage.removeItem(`${prefix}keycloak-id-token`)
  return keycloak.logout()
}

type RealmAccess = {
  licenseserver: [string]
}

export const hasUserRoles = (
  token: string | null,
): { master: boolean; mandant: boolean; user: boolean; anyRole: boolean } => {
  if (!token) {
    return { master: false, mandant: false, user: false, anyRole: false }
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  const realmAccess = JSON.parse(jsonPayload) as RealmAccess
  const master = realmAccess.licenseserver.includes('masteruser')
  const mandant = realmAccess.licenseserver.includes('mandant')
  const user = realmAccess.licenseserver.includes('user')

  return {
    master,
    mandant,
    user,
    anyRole: master || mandant || user,
  }
}

type RefreshToken = {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
  token_type: string
  id_token: string | null
  'not-before-policy': number
  session_state: string
  scope: string
  error: string | null
  error_description: string | null
  error_uri: string | null
}

export const tokenThemeSwitch = async (client: string): Promise<void> => {
  console.log('tokenThemeSwitch', { client })
  const cp = clientProperties(client)
  console.log('tokenThemeSwitch', { cp })
  const [token] = getTokens()
  // const mustChange = prefixKeycloakKeys(client)
  if (cp.keycloakContext === 'NEUTRAL') {
    const newToken = await tokenExchange(token || '', cp.keycloakContext)
    setTokens(newToken.access_token, newToken.id_token || '', newToken.refresh_token, 'neutral@cs-instruments.com')
  }
}

const tokenExchange = async (currentToken: string, context: 'NEUTRAL' | 'CS'): Promise<RefreshToken> => {
  const url = environment[context].KEYCLOAK.URL
  const clientSecret = environment[context].KEYCLOAK.CLIENT_SECRET
  const issuer = environment[context].KEYCLOAK.ISSUER
  const newToken = await fetch(`${url}/iamauth/realms/login/protocol/openid-connect/token`, {
    method: 'POST',
    body: new URLSearchParams({
      client_id: 'login',
      client_secret: clientSecret,
      subject_token: currentToken,
      subject_issuer: issuer,
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
    }),
  })
  const tokens = ((await newToken.json()) as unknown) as RefreshToken
  console.log({ tokens })
  return tokens
}
