import { atom } from 'recoil'

const getCurrentClientState = () => {
  const client = localStorage.getItem('client') || ''
  return client || 'mandant@cs-instruments.com'
}
const getCurrentThemeState = () => {
  const client = localStorage.getItem('client') || ''
  const theme = client === 'neutral@cs-instruments.com' ? 'neutralLsLight' : 'csLight'
  return theme
}

export const themeState = atom({
  key: 'ThemeState',
  default: getCurrentThemeState() as
    | 'csLight'
    | 'neutralLight'
    | 'bekoLight'
    | 'testoLight'
    | 'ipfLight'
    | 'neutralLsLight',
})

export const mandantState = atom({
  key: 'MandantState',
  default: getCurrentClientState(),
})
export const paginationState = atom({
  key: 'PaginationState',
  default: 1,
})
