import React, { useCallback, useEffect, useState } from 'react'
import { AppTop } from '@csinstruments/cs-react-theme/dist/components/AppTop/AppTop'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { getApolloClient } from '../../../helpers/apolloClient'
import { keycloakLogout, setKeycloakClient, tokenThemeSwitch } from '../../../helpers/keycloakService'
import { useTranslation } from '../../../hooks/useTranslation'
import { Tooltip } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useClientsQuery, useSetClientMutation } from '../../../../api/models'
import { useRecoilState } from 'recoil'
import { mandantState, themeState } from '../../../helpers/recoil'

type ClientProps = {
  theme: 'neutralLsLight' | 'csLight' | 'neutralLight' | 'bekoLight'
  keycloakContext: 'NEUTRAL' | 'CS'
}
export const clientProperties = (client: string): ClientProps => {
  if (client === 'neutral@cs-instruments.com') {
    return {
      theme: 'neutralLsLight',
      keycloakContext: 'NEUTRAL',
    }
  }
  return {
    theme: 'csLight',
    keycloakContext: 'CS',
  }
}

export const LicenseServerAppTop: React.FC = () => {
  const [_, setThemeState] = useRecoilState(themeState)
  const { t } = useTranslation()
  const { data: clients, loading } = useClientsQuery()
  const [activeClient, setActiveClient] = useRecoilState(mandantState)
  const [setClient] = useSetClientMutation({ variables: { client: activeClient } })

  const logout = useCallback(() => {
    keycloakLogout()
    getApolloClient().resetStore()
  }, [])

  // useEffect(() => {
  //   if (clients && !loading) {
  //     setActiveClient(clients?.clients?.find((client) => client?.active)?.name || '')
  //   }
  // }, [clients, loading])

  // useEffect(() => {}, [activeClient, setThemeState])

  const tooltip = t('actions.logout')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const c = event.target.value as string
    // setClient({ variables: { client: c } }).then(() => tokenThemeSwitch(c).then(() => setActiveClient(c)))
    setClient({ variables: { client: c } }).then(() => {
      setKeycloakClient(c)
      setActiveClient(c)
      const cp = clientProperties(c)
      setThemeState(cp.theme)
      tokenThemeSwitch(c).catch(console.error)
    })
  }

  return (
    <>
      <AppTop>
        <FormControl>
          {clients?.clients && (
            <Select value={activeClient} onChange={handleChange} style={{ color: '#fff' }}>
              {clients?.clients.map((client) => (
                <MenuItem key={client?.name} value={client?.name}>
                  {client?.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <Tooltip title={tooltip}>
          <ExitToAppOutlinedIcon style={{ marginLeft: 10, cursor: 'pointer' }} onClick={logout} />
        </Tooltip>
      </AppTop>
    </>
  )
}

export default LicenseServerAppTop
